
try {
	window._ = require( 'lodash' );
	window.$ = window.jQuery = require( 'jquery' );
	window.Popper = require('popper.js');
	require( 'bootstrap' );
	require('dropzone');
	require( 'pc-bootstrap4-datetimepicker' ); // bootstrap datetime4
	window.lightbox = require( 'lightbox2' ); // lightbox
	window.nestable = require('nestable2'); // nestable
	window.feather = require('feather-icons'); // feathericon.com
	window.tinymce = require('tinymce'); //tinymce
	window.ClipboardJs = require ('clipboard'); // clipboard
	require('owl.carousel'); // owl slider
	window.Sortable = require('sortablejs');
	// html5 player
	window.Plyr = require('plyr');
	require('animate.css');
}
catch ( e ) {
	alert('스크립트 렌더링 오류');
	console.log(e);
}


window.axios = require( 'axios' );
window.axios.defaults.headers.common[ 'X-Requested-With' ] = 'XMLHttpRequest';

let token = document.head.querySelector( 'meta[name="csrf-token"]' );

if ( token ) {
	window.axios.defaults.headers.common[ 'X-CSRF-TOKEN' ] = token.content;
}
else {
	console.error( 'CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token' );
}

const apiToken = document.head.querySelector('meta[name="api-token"]');
if (apiToken) {
	window.axios.defaults.headers.common['authorization'] = `Bearer ${apiToken.content}`;
}

window.setCookie = function(name, value, day) {
	var date = new Date();
	date.setTime(date.getTime() + day * 60 * 60 * 24 * 1000);
	document.cookie = name + '=' + value + ';expires=' + date.toUTCString() + ';path=/';
};
window.getCookie = function(name) {
	var value = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
	return value? value[2] : null;
};
window.deleteCookie = function(name) {
	var date = new Date();
	document.cookie = name + "= " + "; expires=" + date.toUTCString() + "; path=/";
};
