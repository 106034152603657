require( './bootstrap' );

window.currentUrl = location.origin + location.pathname;
feather.replace();

(function ( $ ) {
	// 네비게이션 active
	const mainNav = $('#mainNav');
	mainNav.find( 'a[href="' + currentUrl + '"]' ).parents( '.nav-item' ).addClass( 'active' );
	mainNav.find( 'a[href="' + currentUrl + '"].dropdown-item' ).addClass( 'active' );
	$( '#adminSideMenu' ).find( 'a[href="' + currentUrl + '"]' ).parents( '.submenu.collapse' ).collapse( 'show' );

	$( '.sidemenu a.nav-link[href="' + currentUrl + '"]' ).addClass( 'active' );

	lightbox.option( {
		                 'resizeDuration': 200,
		                 'wrapAround': true,
		                 'fadeDuration': 200
	                 } );
	$( function () {
		$( '#flash-overlay-modal' ).modal();
		$( '[data-toggle="tooltip"]' ).tooltip();
		$( 'div.alert' ).not( '.alert-important' ).delay( 3000 ).fadeOut( 350 );
	} );

	// AnimateCss 함수 확장
	$.fn.extend( {
		             animateCss: function ( animationName, callback ) {
			             var animationEnd = (function ( el ) {
				             var animations = {
					             animation: 'animationend',
					             OAnimation: 'oAnimationEnd',
					             MozAnimation: 'mozAnimationEnd',
					             WebkitAnimation: 'webkitAnimationEnd',
				             };

				             for ( var t in animations ) {
					             if ( el.style[ t ] !== undefined ) {
						             return animations[ t ];
					             }
				             }
			             })( document.createElement( 'div' ) );

			             this.addClass( 'animated ' + animationName ).one( animationEnd, function () {
				             $( this ).removeClass( 'animated ' + animationName );

				             if ( typeof callback === 'function' ) callback();
			             } );

			             return this;
		             },
	             } );

	window.trimRight = function ( str ) {
		return str.replace( /\s*$/g, "" );
	};

	//뒤의 공백 문자열을 제거
	window.trimLeft = function ( str ) {
		return str.replace( /^\s*/g, "" );
	};

	// 앞뒤 공백문자열을 제거
	window.trim = function ( str ) {
		return str.replace( /(^\s*)|(\s*$)/gi, "" );
	};

	// 미디어 라이브러리 컨트롤
	window.loadLibrary = function ( page_url ) {

		const target = $( '#media-items .row' );

		if ( target.find( '.card-img-top' ).length > 0 ) {
			target.empty().html( '<div class="col"><img src="/images/loading50.gif" alt="loading..." class="d-flex mx-auto my-5"></div>' );
		}

		if ( page_url === undefined ) {
			page_url = '/admin/media-library?page=1';
		}

		axios.get( page_url ).then( function ( response ) {

			if ( response.status === 200 ) {
				const data = response.data;
				const prev_page_url = data.prev_page_url;
				const next_page_url = data.next_page_url;
				const current_page = data.current_page;
				const code = [];

				$.each( data.data, function () {
					const filename = this.filename;
					const mimetype = this.mimetype;
					const size = this.size;
					const path = '/storage/' + this.path;
					const created_at = this.created_at;
					let tag = '<div class="col-sm-3">';

					tag += '<div class="card mb-2">';

					if ( mimetype.split( '/' )[ 0 ] == 'image' ) {
						tag += '<img src="' + path + '" class="card-img-top img-fluid d-block mx-auto" alt="' + filename + '">';
					}

					tag += '<div class="card-body">' +
					       '<p class="filename">';

					if ( mimetype.split( '/' )[ 0 ] != 'image' ) {
						tag += '<i data-feather="file"></i><br>' + filename;
					}

					tag += '(' + FileSize( size, true ) + ')</p>' +
					       '<a href="javascript:;" class="card-link addMedia" data-id="' + this.id + '" data-path="' + path + '" data-mimetype="' + mimetype.split( '/' )[ 0 ] + '" data-filename="' + filename + '">본문 추가</a>' +
					       '<a href="javascript:;" class="card-link addFeaturedImage" data-id="' + this.id + '" data-path="' + path + '" data-mimetype="' + mimetype.split( '/' )[ 0 ] + '" data-filename="' + filename + '">대표이미지 설정</a>' +
					       '</div>' +
					       '</div>';

					tag += '</a></div>';
					code.push( tag );
				} );

				let paginate = '<div class="col-sm-12 p-3 text-center">';
				if ( null !== prev_page_url ) {
					paginate += '<a href="javascript:;" class="btn btn-sm btn-secondary" onclick="loadLibrary(\'' + prev_page_url + '\')">이전</a>';
				}
				if ( null !== next_page_url ) {
					paginate += '<a href="javascript:;" class="btn btn-sm btn-secondary" onclick="loadLibrary(\'' + next_page_url + '\')">다음</a>';
				}

				paginate += '</div>';

				target.empty().append( code.join( '' ) + paginate );

				feather.replace();
			}
		} )
		     .catch( function ( error ) {
			     alert( '오류 발생' );
			     console.log( error );
		     } );
	}

	// 미디어 라이브러리 : 미디어 본문에 추가
	$( document ).on( 'mousedown', '.addMedia', function ( e ) {
		e.preventDefault();
		const parent = $( '#mediaLibWindow' );
		const url = $( this ).data( 'path' );
		const filename = $( this ).data( 'filename' );
		const mimetype = $( this ).data( 'mimetype' );
		const code = [];

		if ( mimetype === 'image' ) {
			code.push( '<img src="' + url + '" class="img-fluid d-block my-1 mx-auto">' );
		}
		else {
			code.push( '<a href="' + url + '"><i data-feather="file"></i> ' + filename + '</a>' );
		}

		tinyMCE.execCommand( 'mceInsertContent', false, code.join( '' ) );
		parent.fadeOut();
	} );

	// 미디어 라이브러리 : Featured Image에 추가
	$( document ).on( 'mousedown', '.addFeaturedImage', function () {
		const parent = $( '#mediaLibWindow' );
		const url = $( this ).data( 'path' );
		const target = $( '#featuredImage' );
		if ( null !== url ) {
			target.val( url );
			$( '#featuredPreview' ).empty().append( '<img src="' + url + '" class="img-fluid d-block mx-auto my-1">' );
		}
		parent.fadeOut();
	} );

	// 업로드 트리거
	$( '#uploadTrigger, #featuredImageUploadTrigger' ).click( function () {
		$( '#fileuploadzone' ).fadeIn();
	} );

	// 미디어 라이브러리 트리거
	$( 'button[data-action="media-library-trigger"]' ).click( function () {
		let target = $( '#mediaLibWindow' );
		loadLibrary();
		if ( target.css( 'display' ) == 'none' ) {
			target.css( 'display', '' );
		}
		else {
			target.css( 'display', 'none' );
		}
	} );

	// 미디어 라이브러리 닫기 버튼
	$( '#closeMediaLibrary' ).click( function () {
		$( '#mediaLibWindow' ).fadeOut();
	} );

	$( '#checkall' ).click( function () {
		if ( $( this ).is( ':checked' ) ) {
			$( 'table input[type="checkbox"]' ).prop( 'checked', true );
		}
		else {
			$( 'table input[type="checkbox"]' ).prop( 'checked', false );
		}
	} );

	$( 'aside .nav-link' ).each( function () {
		if ( $( this ).attr( 'href' ) == currentUrl ) {
			$( this ).addClass( 'active' );
		}
	} );

	var tinymceSetup = function ( ed ) {
		ed.on( 'init', function () {
			this.getDoc().body.style.fontSize = '16px';
			this.getDoc().body.style.fontFamily = 'Gothic A1';
			this.getDoc().body.style.padding = '10px';
			this.getDoc().body.style.boxShadow = '0';
			this.getDoc().body.style.backgroundColor = "white";
		} );
	};

	const tinymceConfig = {
		setup: tinymceSetup,
		relative_urls: false,
		selector: 'textarea#content',
		theme: "modern",
		content_css: '/css/app.css',
		language: 'ko_KR',
		menubar: false,
		statusbar: true,
		resize: true,
		height: 500,
		allow_script_urls: true,
		allow_html_in_named_anchor: true,
		entity_encoding: "raw",
		extended_valid_elements: "script[src|type|language],style",
		plugins: 'advlist code media link lists colorpicker paste table textcolor print emoticons preview',
		toolbar: [ 'styleselect forecolor backcolor indent outdent bold italic blockquote removeformat alignleft alignjustify aligncenter alignright bullist numlist media link table code' ],
		skin: 'lightgray',
		body_class: 'entry_content',
		branding: false,
		valid_elements : '*[*]'
	};

	tinymce.init( tinymceConfig );

	$( '#closeUploadzone' ).click( function () {
		$( '#fileuploadzone' ).fadeOut();
	} );


	// Dropzone setting
	var previewNode = document.querySelector( "#template" );
	if ( null !== previewNode ) {
		previewNode.id = "";
		var previewTemplate = previewNode.parentNode.innerHTML;
		previewNode.parentNode.removeChild( previewNode );

		var myDropzone = new Dropzone( document.body, { // Make the whole body a dropzone
			url: '/admin/uploads/store', // Set the url
			paramName: "attachedImage",
			params: {
				_token: csrfToken
			},
			acceptedFiles: 'image/*,.pdf,.xls,.xlsx,.doc,.docx,.ppt,.pptx,.hwp,.txt,.zip',
			thumbnailMethod: "contain",
			thumbnailWidth: 200,
			thumbnailHeight: 150,
			parallelUploads: 20,
			previewTemplate: previewTemplate,
			autoQueue: true,
			previewsContainer: "#previews",
			clickable: ".uploadTrigger"
		} );

		myDropzone.on( "success", function ( file, responseText ) {
			const self = $( file.previewElement );
			const mediaWindow = $( '#mediaLibWindow' );
			const medialibrary = $( '#mediaLibrarySection' );
			const res = responseText;

			self.find( '.progress' ).css( 'display', 'none' );
			self.find( 'button.delete' ).data( 'file-id', res.id );
			self.find( 'button.append' ).data( {
				                                   'file-id': res.id,
				                                   'file-url': res.path,
				                                   'file-mime': res.mimetype,
				                                   'file-name': res.filename
			                                   } );
			self.find( 'button.appendFeatured' ).data( {
				                                           'file-id': res.id,
				                                           'file-url': res.path,
				                                           'file-mime': res.mimetype,
				                                           'file-name': res.filename
			                                           } );


			let media_tpl = '<div class="col-sm-3">' +
			                '<div class="card mb-2">' +
			                '<img src="/storage/' + res.path + '" class="card-img-top img-fluid d-block mx-auto" alt="' + res.filename + '">' +
			                '<div class="card-body">' +
			                '<p class="filename">' + res.filename + '</p>' +
			                '<a href="javascript:;" class="card-link addMedia" data-id="' + res.id + '" data-path="/storage/' + res.path + '" data-mimetype="' + res.mimetype + '" data-filename="' + res.filename + '">본문 추가</a>' +
			                '<a href="javascript:;" class="card-link addFeaturedImage" data-id="' + res.id + '" data-path="/storage/' + res.path + '" data-mimetype="' + res.mimetype + '" data-filename="res.filename">대표이미지 설정</a>' +
			                '</div>' +
			                '</div>' +
			                '</div>';

			let style = isImage( res.mimetype ) ? 'background-image:url(/storage/' + res.path + ')' : '';


			let tpl2 = '<div class="col-sm-3" id="file-' + res.id + '">' +
			           '<figure>' +
			           '<div class="img-box mb-1 border d-flex flex-row justify-content-center align-items-center" style="' + style + '">';
			if ( isImage( res.mimetype ) ) {
				tpl2 += '<a href="/storage/' + res.path + '" data-lightbox="lightbox" data-title="' + res.filename + '">' +
				        '<img src="/images/blank_img.gif" alt="' + res.filename + '" class="fakeimg">' +
				        '</a>';
			}
			else {
				tpl2 += '<i data-feather="file" style="width:48px;height:48px;"></i>';
			}
			tpl2 += '<button class="btn btn-sm btn-danger media-delete-button" data-file-id="' + res.id + '" type="button">' +
			        '<i data-feather="x"></i>' +
			        '</button>' +
			        '<button data-clipboard-text="/storage/' + res.path + '" class="btn btn-light btn-sm btn-copy" type="button">Copy</button>' +
			        '</div>' +
			        '<figcaption class="figure-caption">' +
			        '<p class="text-truncate" title="' + res.filename + '">' +
			        '<a href="/download/' + res.id + '">' + res.filename + '</a>' +
			        '</p>' +
			        '</figcaption>' +
			        '</figure>' +
			        '</div>';

			if ( mediaWindow.length > 0 ) {
				mediaWindow.find( '#media-items .row' ).prepend( media_tpl );
			}

			if ( medialibrary.length > 0 ) {
				medialibrary.prepend( tpl2 );
				feather.replace();
			}

			console.log( mediaWindow.length, medialibrary.length );

			self.remove();
		} );

		myDropzone.on( 'queuecomplete', function () {
			$( '#fileuploadzone' ).hide();
			$( 'button[data-action="media-library-trigger"]' ).trigger( 'click' );
		} );

		myDropzone.on( 'error', function ( file, error ) {
			alert( '업로드 오류' );
			console.log( error );
		} );
	}

	function isImage( mimetype ) {
		const type = mimetype.split( "/" );
		if ( type[ 0 ] == 'image' ) {
			return true;
		}

		return false;
	}

	function deleteFile( parent, id ) {
		var data = { _token: csrfToken, _method: 'delete' };

		axios.post( '/admin/uploads/destroy/' + id, data )
		     .then( function ( response ) {

			     if ( response.status === 200 ) {
				     $( parent ).remove();
			     }
			     if ( response.status === 404 ) {
				     alert( '파일 삭제 오류' );
				     return false;
			     }
		     } )
		     .catch( function ( error ) {
			     alert( '통신오류!' );
			     console.log( error.response.data );
			     return false;
		     } );
	}

	$( document ).on( 'click', 'button.delete', function ( e ) {
		e.preventDefault();
		var id = $( this ).data( 'file-id' ),
				parent = $( this ).parents( '.file-row' )[ 0 ];
		deleteFile( parent, id );
	} );

	$( document ).on( 'click', 'button.append', function ( e ) {
		e.preventDefault();

		const id = $( this ).data( 'file-id' );
		const url = $( this ).data( 'file-url' );
		const mimeType = $( this ).data( 'file-mime' );
		const filename = $( this ).data( 'file-name' );
		let code = '';

		if ( mimeType.split( '/' )[ 0 ] == 'image' ) {
			code = '<div class="text-center"><img src="' + decodeURIComponent( '/storage/' + url ) + '" class="img-fluid center-block" alt="' + filename + '"></div>';
		}
		else {
			code = '<a href="/file/download/' + url + '">' + filename + '</a>';
		}
		tinyMCE.execCommand( 'mceInsertContent', false, code );
	} );

	$( document ).on( 'click', 'button.appendFeatured', function ( e ) {
		e.preventDefault();
		var id = $( this ).data( 'file-id' ),
				url = '/storage/' + $( this ).data( 'file-url' ),
				mimeType = $( this ).data( 'file-mime' ),
				filename = $( this ).data( 'file-name' );

		if ( $( '#featuredImage' ).length === 1 ) {
			if ( mimeType.split( '/' )[ 0 ] != 'image' ) {
				alert( '이미지를 선택하세요.' );
				return false;
			}
			$( '#featuredImage' ).val( url );
			$( '#featuredPreview' ).empty().append( '<a href="' + url + '" data-lightbox="lightbox">' +
			                                        '<img src="' + url + '" class="img-fluid center-block">' +
			                                        '</a>' );
		}
	} );


	$( document ).on( 'focusin', function ( e ) {
		if ( $( e.target ).closest( ".mce-window" ).length ) {
			e.stopImmediatePropagation();
		}
		if ( $( e.target ).closest( ".moxman-window" ).length ) {
			e.stopImmediatePropagation();
		}
	} );

	window.makeSlug = function ( string ) {
		var regExp = /[\s\{\}\[\]\/?.,;:|\)*~`!^\_+<>@\#$%&\\\=\(\'\"]/gi,
				result = string.replace( regExp, "-" ),
				regExp2 = /\-$/gi,
				final = result.replace( regExp2, '' );

		return final.toLowerCase();
	}
	const datetimepickerConfig = {
		locale: 'ko',
		dayViewHeaderFormat: 'YYYY년 MM월',
		format: 'YYYY-MM-DD HH:mm:ss',
		sideBySide: true,
		icons: {
			time: 'fa fa-clock-o',
			date: 'fa fa-calendar',
			up: 'fa fa-chevron-up',
			down: 'fa fa-chevron-down',
			previous: 'fa fa-chevron-left',
			next: 'fa fa-chevron-right',
			today: 'fa fa-calendar-o',
			clear: 'fa fa-trash',
			close: 'fa fa-times'
		}
	};

	const datetimepickerConfig2 = {
		locale: 'ko',
		dayViewHeaderFormat: 'YYYY년 MM월',
		format: 'YYYY-MM-DD',
		icons: {
			time: 'fa fa-clock-o',
			date: 'fa fa-calendar',
			up: 'fa fa-chevron-up',
			down: 'fa fa-chevron-down',
			previous: 'fa fa-chevron-left',
			next: 'fa fa-chevron-right',
			today: 'fa fa-calendar-o',
			clear: 'fa fa-trash',
			close: 'fa fa-times'
		}
	};

	const dtConfig3 = {
		locale: 'ko',
		dayViewHeaderFormat: 'YYYY년 MM월',
		format: 'YYYY/MM/DD ddd'
	};


	$( '.datepicker' ).datetimepicker( dtConfig3 );
	$( '.datetimepicker' ).datetimepicker( datetimepickerConfig );
	$( '.datetimepicker-dateonly' ).datetimepicker( datetimepickerConfig2 );

	window.FileSize = function ( bytes, si ) {
		var thresh = si ? 1000 : 1024;
		if ( Math.abs( bytes ) < thresh ) {
			return bytes + ' B';
		}
		var units = si
				? [ 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB' ]
				: [ 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB' ];
		var u = -1;
		do {
			bytes /= thresh;
			++u;
		} while ( Math.abs( bytes ) >= thresh && u < units.length - 1 );
		return bytes.toFixed( 1 ) + ' ' + units[ u ];
	}

	window.beforeSubmitWarning = function ( obj ) {
		if ( !confirm( '이 글을 삭제할까요?' ) ) return false;
		obj.submit();
	}

	window.replaceClass = function ( obj, classFrom, classTo ) {
		var t = $( obj );
		if ( t.hasClass( classFrom ) ) {
			t.removeClass( classFrom ).addClass( classTo );
		}
	}

	window.nl2br = ( str ) => {
		let string = removeNl( str );
		string = string.replace( '/\s/gi', '' );
		string = stripTag( string );
		string = string.replace( /\n/gi, "<br>" );

		return string;
	}

	window.stripTag = ( str ) => {
		return str.replace( /(<([^>]+)>)/ig, "" );
	}

	window.removeNl = ( str ) => {
		return str.replace( /\s\n/gi, "" );
	}

	window.showDateKr = ( str ) => {

		const dt = new Date( str );

		/*return dt.getFullYear() + '년'  +
		 (dt.getMonth() + 1) + '월 ' +
		 dt.getDate() + '일 ' +
		 dt.getHours() + '시 ' +
		 dt.getMinutes() + '분';*/

		return dt.toLocaleString();
	}

	window.makeFBtag = ( response ) => {
		const d = response.data;
		const code = [];
		const tg = $( '#facebook-feed .feed' );


		$.each( d, function () {
			if ( this.message && this.type !== 'event' ) {
				const now = showDateKr( this.created_time );
				const message = this.message.length > 300 ? this.message.substring( 0, 300 ) + '...' : this.message;
				let html = '<div class="row">' +
				           '<div class="col-12 col-lg-4">' +
				           '<div style="max-height:200px;overflow-y:hidden;" class="rounded">' +
				           '<a href="https://facebook.com/' + this.id + '" target="_blank">' +
				           '<img src="' + this.full_picture + '" class="img-fluid">' +
				           '</a>' +
				           '</div>' +
				           '</div>' +
				           '<div class="col-12 col-lg-8">' +
				           '<p class="text-primary"><b>' + now + '</b></p>' +
				           '<p><a href="' + this.id + '" target="_blank">' + message + '</a></p>' +
				           '</div>' +
				           '</div>';


				code.push( html );
			}
		} );
		tg.html( code.join( '' ) );
		tg.owlCarousel(
				{
					loop: true,
					margin: 16,
					responsiveClass: true,
					nav: false,
					dots: false,
					autoplay: true,
					responsive: {
						0: { items: 1 },
						600: { items: 1 },
						1000: { items: 1 }
					}
				}
		);
	}

	window.makeFacebookEvents = ( res ) => {
		const d = res.data;
		const tg = $( '#facebook-event-feed .feed' );
		const code = [];

		$.each( d, function () {

			const html = '<div class="col-4">' +
			             '<div class="card event">' +
			             '<img src="' + this.cover.source + '" class="card-img-top" alt="">' +
			             '<h5 class="card-header text-center">' + this.name + '</h5>' +
			             '<div class="card-body">' +
			             '<p class="card-text">' + this.description + '</p>' +
			             '<ul class="list-unstyled">' +
			             '<li><b>시작</b> : ' + showDateKr( this.start_time ) + '</li>' +
			             '<li><b>종료</b> : ' + showDateKr( this.end_time ) + '</li>' +
			             '<li><b>장소</b> : ' + this.place.name + '</li>' +
			             '</ul>' +
			             '</div>' +
			             '</div>' +
			             '</div>';

			code.push( html );
		} );

		tg.html( code.join( '' ) );
	}

	$( '.banner-carousel' ).owlCarousel( {
		                                     loop: true,
		                                     margin: 10,
		                                     responsiveClass: true,
		                                     nav: false,
		                                     dots: false,
		                                     autoplay: true,
		                                     autoplayTimeout: 5000,
		                                     responsive: {
			                                     0: {
				                                     items: 2,
			                                     },
			                                     600: {
				                                     items: 3,
			                                     },
			                                     1000: {
				                                     items: 5,
			                                     }
		                                     }
	                                     } )

	window.showResult = ( tag, status ) => {
		let tg = $( '#series-contents-current .row' );

		if ( status === 0 ) {
			tg = $( '#series-contents-old' );
		}

		tg.html( tag );
	};

	window.loadSeriesSermons = ( page, status ) => {
		const current_url = location.origin + location.pathname;

		axios.get( '/api/get/series?page=' + page + '&ongoing=' + status )
		     .then( function ( res ) {
			     const d = res.data;
			     const total = d.series.total;
			     const per_page = d.series.per_page;
			     const current_page = d.series.currrent_page;
			     const last_page = d.series.last_page;
			     const first_page_url = d.series.first_page_url;
			     const last_page_url = d.series.last_page_url;
			     const next_page_url = d.series.next_page_url;
			     const prev_page_url = d.series.prev_page_url;
			     const data = d.series.data;

			     let tpl = '';

			     if ( status === 0 ) {
				     // 종료된 설교 시리즈
				     tpl = '<div class="row">' +
				           '<div class="col-3"><div class="img-wrap my-2"></div></div>' +
				           '<div class="col">' +
				           '<div class="d-flex flex-column justify-content-center my-2">' +
				           '<h5 class="series-title my-1"></h5>' +
				           '<a href="" class="detail_link">내용 보기</a>' +
				           '</div>' +
				           '</div>' +
				           '</div>';
			     }
			     else {
				     // 진행 중 설교 시리즈
				     tpl = '<div class="col-sm-4">' +
				           '<div class="card series-card border rounded-0 mb-4">' +
				           '<div class="img-wrap position-relative"></div>' +
				           '<div class="card-body p-2 text-center">' +
				           '<h5 class="series-title text-center my-3"></h5>' +
				           '<p class="text-danger"><i class="fa fa-circle text-success"></i> ' +
				           '<a href="">내용 보기</a>' +
				           '</p>' +
				           '</div>' +
				           '</div>' +
				           '</div>';
			     }

			     let code = [];

			     $.each( data, function () {
				     const epi = $( tpl ).clone();

				     if ( status === 0 ) {
					     epi.find( '.img-wrap' ).html( '<a href="' + current_url + '?cid=' + this.id + '"><img src="/storage/' + this.thumbnail + '" class="border img-fluid"></a>' );
					     epi.find( '.detail_link' ).attr( 'href', current_url + '?cid=' + this.id );
					     epi.find( '.series-title' ).text( this.title );
					     code.push( '<div class="row">' + epi.html() + '</div>' );

				     }
				     else {
					     epi.find( '.card' ).attr( 'onclick', 'location.href="' + current_url + '?cid=' + this.id + '"' );
					     epi.find( '.img-wrap' )
					        .attr( 'style', 'height:150px;background-image:url(/storage/' + this.thumbnail + ');background-position:center;background-size:cover;' );
					     epi.find( '.series-title' ).text( this.title );
					     epi.find( 'a' ).attr( 'href', current_url + '?cid=' + this.id );
					     code.push( '<div class="col-sm-4">' + epi.html() + '</div>' );

				     }

			     } );

			     showResult( code.join( '' ), status );

			     if ( status === 0 && total > per_page ) {
				     const pagination = [
					     '<li class="page-item"><a class="page-link" href="' + first_page_url + '&ongoing=0">처음</a></li>',
					     '<li class="page-item"><a class="page-link" href="' + prev_page_url + '&ongoing=0">이전</a></li>'
				     ];

				     for ( let i = 1; i <= last_page; ++i ) {
					     const active = current_page === i ? 'active' : '';
					     const pcode = '<li class="page-item' + active + '">' +
					                   '<a class="page-link" href="{{ url()->current() }}?page=' + i + '&ongoing=0">' + i + '</a>' +
					                   '</li>';
					     pagination.push( pcode );
				     }
				     pagination.push( '<li class="page-item"><a class="page-link" href="' + next_page_url + '&ongoing=0">다음</a></li>' +
				                      '<li class="page-item"><a class="page-link" href="' + last_page_url + '&ongoing=0">마지막</a></li>' );

				     $( '#series-pagination .pagination' ).html( pagination.join( '' ) );
				     $( '#series-pagination' ).show();
			     }

		     } );
	};

	window.getEpisodes = ( cid ) => {
		return axios.get( '/api/get/series/' + cid + '/episodes' );
	};


	$( 'a[data-search-show]' ).click( function () {
		$( '#search-section' ).fadeIn();
	} );
	$( '#search-close button' ).click( function () {
		$( '#search-section' ).css( 'display', 'none' );
	} );


	$( '#next-gen-wrap .photo-item' ).hover( function () {
		anime( {
			       targets: this,
			       duration: 30,
			       scale: 1.2,
			       easing: 'linear'
		       } )
	}, function () {
		anime( {
			       targets: this,
			       duration: 30,
			       scale: 1,
			       easing: 'linear'
		       } )
	} );

	$( '.list-thumbnail' ).hover( function () {
		anime( {
			       targets: this,
			       scale: 1.1
		       } );
	}, function () {
		anime( {
			       targets: this,
			       scale: 1
		       } );
	} );

	window.publishToFacebook = function ( obj ) {
		let tg = obj.parentNode;
		let url = obj.dataset.fbPublish;
		tg.innerHTML = '<i class="fa fa-circle-o-notch fa-spin fa-fw text-success"></i>';
		axios.post( url )
		     .then( function ( r ) {
			     let code = '<a href="https://facebook.com/' + r.data.fb_post_id + '" target="_blank" class="btn btn-sm btn-outline-secondary"><i class="fa fa-link"></i> 보기</a>';
			     tg.innerHTML = code;
		     } )
		     .catch( function ( err ) {
			     if ( err.response ) {
				     alert( err.response.data.message );
			     }
			     else {
				     alert( err.message );
			     }
			     console.log( err );
		     } );

	}

})( jQuery );

const popupModal = document.getElementById('popup');
if (popupModal) {
	const popupCookie = getCookie('hidePopup');
	if (!popupCookie) {
		$(popupModal).modal('show');
	}
}

window.hidePopupToday = () => {
	setCookie('hidePopup', true, 1);
};

$('#carousel-youngchurch').carousel({
	interval: 2000
})
